import React, { useEffect } from "react";
import Sms from "../../assets/icons/sms.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { Button, Grid, Grid2, TextField } from "@mui/material";
import { useLanguageTheme } from "../../hooks/useLanguageTheme";
import {
  useResendSignupSmsMutation,
  useVerifySignupSmsMutation,
} from "../../store/api/authApiSlice";

export const SmsConfirmationContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const langTheme = useLanguageTheme();

  const isRTL = currentLanguage === "he";
  const [code, setCode] = React.useState(["", "", "", ""]);

  const [error, setError] = React.useState("");
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [countdown, setCountdown] = React.useState(60);

  const [resendSms] = useResendSignupSmsMutation();
  const [verifySms] = useVerifySignupSmsMutation();

  const { phoneNumber = "" } = useParams();

  useEffect(() => {
    let timer: any;
    if (isButtonDisabled && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled, countdown]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newCode = [...code];
    newCode[index] = e.target.value.slice(0, 1);
    setCode(newCode);

    if (e.target.value) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  };

  async function handleSmsVerification() {
    try {
      await verifySms({
        phoneNumber,
        smsCode: code.join(""),
      }).unwrap();
      navigate("/signin");
    } catch (error: any) {
      setError(error.message);
      console.log(error.message);
    }
  }

  async function handleResend() {
    try {
      setIsButtonDisabled(true);
      setCountdown(60);
      await resendSms({ phoneNumber }).unwrap();
    } catch (error: any) {
      console.log(error.message);
    }
  }

  return (
    <div className="forgot-p-container">
      <div className="sms-conf-wrapper">
        <div className="forgot-p-email-checked-container">
          <img src={Sms} />
        </div>
        <div
          className="forgot-p-title"
          style={{
            fontFamily: isRTL ? 'Noto Sans Hebrew' : 'Frank Ruhl Libre',
          }}
        >
          {t('sms_confirmation')}
        </div>

        <div
          className="forgot-p-send-email-desc-container"
          style={{
            direction: i18n.dir(),
          }}
        >
          <div
            className="forgot-p-reset-desc"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t('we_sent_code_to')}
          </div>
          <div
            className="forgot-p-send-email-desc-email"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {phoneNumber}
          </div>
        </div>

        <Grid2
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {code.map((_, index) => (
            <Grid item key={index}>
              <TextField
                id={`input-${index}`}
                value={code[index]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e, index)
                }
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: 'center',
                    width: '43px',
                    height: '38px',
                    fontSize: '36px',
                    color: 'rgba(0, 0, 0, 1)',
                    fontFamily: 'DM Sans',
                    fontWeight: 600,
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    borderRadius: '16px',
                    border: code[index]
                      ? '1px solid rgba(129, 154, 221, 1)'
                      : '1px solid rgba(224, 229, 242, 1)',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            </Grid>
          ))}
        </Grid2>

        <div
          style={{
            fontFamily: langTheme.mainFont(),
            fontWeight: 500,
            color: 'rgba(252, 71, 71, 1)',
            lineHeight: '16px',
            marginTop: '20px',
          }}
        >
          {error && t('invalid_code')}
        </div>

        <div
          className="not-registred-yet-container didnt-receive-sms-container"
          style={{
            direction: i18n.dir(),
          }}
        >
          <div
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: 500,
              color: 'rgba(43, 54, 116, 1)',
              fontSize: '16px',
              letterSpacing: '-0.32px',
              lineHeight: '26px',
            }}
          >
            {t('dont_recive_sms')}
          </div>
          <Button
            disabled={isButtonDisabled}
            onClick={handleResend}
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 600 : 500,
              color: 'rgba(215, 161, 72, 1)',
              fontSize: '16px',
              letterSpacing: '-0.32px',
              lineHeight: '26px',
              cursor: 'pointer',
              textTransform: 'none',
            }}
          >
            {isButtonDisabled
              ? `Resend SMS in ${countdown} seconds`
              : t('click_to_resend')}
          </Button>
        </div>
        <button
          type="submit"
          className="signin-form-inputs-signin-btn"
          onClick={handleSmsVerification}
          style={{
            fontFamily: langTheme.mainFont(),
            fontWeight: isRTL ? 700 : 500,
          }}
        >
          {t('submit')}
        </button>
        <BackToLoginBtn />
      </div>
    </div>
  );
};
