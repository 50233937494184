import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import Visibility from '../../assets/icons/visibility.svg';
import VisibilityOff from '../../assets/icons/visibility_off.svg';
import { authTextFieldStyles, ISignupError } from '../../pages/Signup';
import { PhoneSelect } from '../_common/PhoneSelect';
import { ORBlock } from './ORBlock';
import { SignWithGoogleBtn } from './SignWithGoogleBtn';
import { useAppDispatch } from '../../store/store';
import {
  setIsLoginPopupContent,
  setSignupPopupClose,
  authPopupOpenSelector,
} from '../../store/features/common';
import { useSignupMutation } from '../../store/api/authApiSlice';
import { UserType, UserTypeSelect } from './UserTypeSelect';
import { useLanguageTheme } from '../../hooks/useLanguageTheme';
import { BackBtnAuth } from './BackBtnAuth';
import { useSelector } from 'react-redux';

export interface ISignupData {
  fullName: string;
  username: string;
  email: string;
  password: string;
  userType: string;
  phoneNumber: string;
}

export const SignupContent = ({
  additionalDescription,
  isPopup,
}: {
  additionalDescription: string;
  isPopup: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation(['signin']);
  const isRTL = i18n.dir() === 'rtl';
  const langTheme = useLanguageTheme();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [userType, setUserType] = React.useState<UserType>(UserType.HomeBuyer);
  const [selectedCode, setSelectedCode] = React.useState('+972');
  const [termPolicyChecked, setTermPolicyChecked] = React.useState(false);

  const [errors, setErrors] = React.useState<Partial<ISignupError>>({});
  const hasError = useMemo(() => Object.keys(errors).length !== 0, [errors]);
  const [submitError, setSubmitError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [isSubmited, setIsSubmited] = React.useState(false);

  const [signup] = useSignupMutation();

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (data: ISignupData) => {
    setIsSubmited(true);

    const newErrors: Partial<ISignupError> = {};

    // Validate Full Name
    if (!fullName.trim()) {
      newErrors.fullName = t('full_name_required');
    }

    // Validate Email
    if (!email.trim()) {
      newErrors.email = t('email_required');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = t('email_invalid');
    }

    // Validate Phone Number
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    if (!phoneNumber.trim()) {
      newErrors.phoneNumber = t('phone_number_required');
    } else if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = t('invalid_phone_number_format');
    }

    // Validate Password

    if (!password.trim()) {
      newErrors.password = t('password_required');
    } else if (password.length < 8) {
      newErrors.password = t('at_least_8_characters');
    } else if (!/\d/.test(password)) {
      newErrors.password = t('at_least_1_number');
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      newErrors.password = t('at_least_1_special_character');
    }

    if (!termPolicyChecked) {
      newErrors.termPolicyChecked = t('term');
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = t('password_required');
    }

    if (
      password.trim() &&
      confirmPassword.trim() &&
      password !== confirmPassword
    ) {
      newErrors.confirmPassword = t('password_does_not_match');
    }

    setErrors(newErrors);

    // If no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      try {
        const registrationData = {
          fullName: fullName,
          username: crypto.randomUUID(),
          email: email,
          password: password,
          userType: userType,
          phoneNumber: `${selectedCode}${phoneNumber}`,
        };
        localStorage.setItem(
          'registrationData',
          JSON.stringify(registrationData),
        );
        if (selectedCode === '+972') {
          navigate(
            `/choose-verification/${email}/${selectedCode}${phoneNumber}`,
          );
        } else {
          try {
            await signup(registrationData).unwrap();
            navigate(`/confirm-email/${email}`);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (err) {
        console.log('log:err ', err);
        setSubmitError(t('registration.error.unableToComplete'));
      }
    }
  };

  const formContext = useForm<ISignupData>({
    defaultValues: {
      fullName: '',
      username: '',
      email: '',
      password: '',
      userType: '',
      phoneNumber: '',
    },
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    mode: 'onSubmit',
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.email = t('email_required');
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      newErrors.email = t('email_invalid');
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFullName(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.fullName = t('full_name_required');
    } else {
      delete newErrors.fullName;
    }

    setErrors(newErrors);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);

    const newErrors = { ...errors };

    // Regular expression for validating international phone numbers
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    // Combine the selected country code with the phone number
    const fullPhoneNumber = selectedCode + value;

    // Check if the input is empty
    if (!value.trim()) {
      newErrors.phoneNumber = t('phone_number_required');
    }
    // Check if the combined phone number matches the format
    else if (!phoneRegex.test(fullPhoneNumber)) {
      newErrors.phoneNumber = t('invalid_phone_number_format');
    }
    // Check if the phone number length is plausible
    else if (value.length < 7 || fullPhoneNumber.length > 15) {
      newErrors.phoneNumber = t('phone_number_length_invalid');
    }
    // If the input is valid, remove any existing error message
    else {
      delete newErrors.phoneNumber;
    }

    setErrors(newErrors);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.password = t('password_required');
    } else if (value.length < 8) {
      newErrors.password = t('at_least_8_characters');
    } else if (!/\d/.test(value)) {
      newErrors.password = t('at_least_1_number');
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      newErrors.password = t('at_least_1_special_character');
    } else {
      delete newErrors.password;
    }

    setErrors(newErrors);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);

    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.confirmPassword = t('password_required');
    } else if (password.trim() && value.trim() && password !== value) {
      newErrors.confirmPassword = t('password_does_not_match');
    } else {
      delete newErrors.confirmPassword;
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    const newErrors = { ...errors };

    if (!termPolicyChecked) {
      newErrors.termPolicyChecked = t('term');
    } else {
      delete newErrors.termPolicyChecked;
    }
    setErrors(newErrors);

    if (isSubmited) {
      if (termPolicyChecked) {
        delete newErrors.termPolicyChecked;
      } else {
        newErrors.termPolicyChecked = t('term');
      }

      setErrors(newErrors);
    }
  }, [termPolicyChecked, isSubmited]);

  const signupPopupOpen = useSelector(authPopupOpenSelector);

  const handleGoToSignin = () => {
    if (signupPopupOpen) {
      dispatch(setIsLoginPopupContent({ isLoginPopupContent: true }));
    } else {
      navigate('/signin');
    }
  };

  return (
    <div
      className="signin-form-section"
      style={{
        paddingTop: isPopup ? 0 : '50px',
        paddingBottom: isPopup ? 0 : '50px',
        padding: isPopup ? '' : '45px 20px',
      }}
    >
      <div
        className="signin-form-container"
        style={{
          direction: i18n.dir(),
        }}
      >
        {!isPopup && <BackBtnAuth />}
        <div
          className="signin-form-title-container"
          style={{
            direction: i18n.dir(),
          }}
        >
          <div
            className="signin-form-title-signin"
            style={{
              fontFamily: isRTL ? 'Noto Sans Hebrew' : 'Frank Ruhl Libre',
              textTransform: 'capitalize',
            }}
          >
            {t('sign_up')}
          </div>

          {!isPopup && (
            <>
              <div
                className="signin-vertical-divider"
                style={{
                  marginRight: isRTL ? '12px' : '16px',
                  marginLeft: isRTL ? '12px' : '20px',
                }}
              ></div>
              <div
                className="signin-form-title-welcom"
                style={{
                  fontFamily: langTheme.mainFont(),
                }}
              >
                {t('welcome_on_board')}
              </div>
            </>
          )}
        </div>
        {additionalDescription && (
          <div
            className="signup-additional-desc"
            style={{
              fontSize: downSm ? '14px' : '16px',
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t(additionalDescription)}
          </div>
        )}

        <SignWithGoogleBtn type={'signup'} />
        <ORBlock />

        <FormContainer
          formContext={formContext}
          onSuccess={(data) => onSubmit(data)}
        >
          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
            }}
          >
            {t('user_type')}
          </div>

          <UserTypeSelect userType={userType} setUserType={setUserType} />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: '5px',
            }}
          >
            {t('full_name')}
          </div>
          <TextField
            type="text"
            name="fullName"
            value={fullName}
            onChange={handleFullNameChange}
            fullWidth
            required
            inputProps={{
              maxLength: 100,
            }}
            placeholder={t('full_name_example')}
            error={!!errors.fullName}
            helperText={errors.fullName || ' '}
            className={`form-input-wrapper ${!!errors.fullName ? 'error' : ''}`}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.fullName)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? 'right' : 'left',
                fontFamily: langTheme.mainFont(),
              },
            }}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: '5px',
            }}
          >
            {t('email')}
          </div>
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            required
            inputProps={{
              maxLength: 254,
            }}
            error={!!errors.email}
            helperText={errors.email || ' '}
            placeholder={t('email_placeholder')}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.email)
            }
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? 'right' : 'left',
                fontFamily: langTheme.mainFont(),
              },
            }}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: '5px',
            }}
          >
            {t('phone_number')}
          </div>

          <TextField
            type="tel"
            fullWidth
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber || ' '}
            inputProps={{
              maxLength: 15,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? 'right' : 'left',
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneSelect
                    selectedCode={selectedCode}
                    handleCodeChange={handleCodeChange}
                  />
                </InputAdornment>
              ),
            }}
            sx={(theme) => ({
              ...authTextFieldStyles.textField(
                theme,
                isRTL,
                !!errors.phoneNumber,
              ),
              input: {
                textAlign: isRTL ? 'right' : 'left',
                direction: i18n.dir(),
              },
            })}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: '5px',
            }}
          >
            {t('password')}
          </div>
          <TextField
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            error={!!errors.password}
            helperText={errors.password || ' '}
            fullWidth
            required
            inputProps={{
              maxLength: 64,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? 'right' : 'left',
                fontFamily: langTheme.mainFont(),
              },
            }}
            placeholder={t('password_placeholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <img
                        src={VisibilityOff}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                    ) : (
                      <img
                        src={Visibility}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={(theme) =>
              authTextFieldStyles.textField(theme, isRTL, !!errors.password)
            }
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: langTheme.mainFont(),
              marginTop: '5px',
            }}
          >
            {t('confirm_password')}
          </div>
          <TextField
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            required
            placeholder={t('confirm_password')}
            value={confirmPassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword || ' '}
            onChange={handleConfirmPasswordChange}
            inputProps={{
              maxLength: 64,
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: isRTL ? 'right' : 'left',
                fontFamily: langTheme.mainFont(),
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleConfirmPasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <img
                        src={VisibilityOff}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                    ) : (
                      <img
                        src={Visibility}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                border: '1px solid rgba(224, 229, 242, 1)',
                borderRadius: '16px',
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                border: '1px solid rgba(129, 154, 221, 1)',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '14px',
                color: 'rgba(43, 54, 116, 1)',
                fontFamily: 'DM Sans',
                fontWeight: 500,
                lineHeight: '14px',
                letterSpacing: '-0.32px',
              },
              '& .MuiOutlinedInput-input::placeholder': {
                color: 'rgba(163, 174, 208, 1)',
                opacity: 1,
              },
              '& .MuiOutlinedInput-input:focus::placeholder': {
                opacity: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />

          <div
            className="sign-up-term-policy"
            style={{
              marginTop: '5px',
            }}
          >
            <Checkbox
              checked={termPolicyChecked}
              onChange={() => setTermPolicyChecked(!termPolicyChecked)}
              sx={{
                '& .MuiSvgIcon-root': {
                  fill: 'rgba(43, 54, 116, 1)',
                },
                '&.Mui-checked .MuiSvgIcon-root': {
                  fill: 'rgba(3, 74, 115, 1)',
                },
                '&.Mui-disabled .MuiSvgIcon-root': {
                  fill: 'rgba(200, 200, 200, 1)',
                },
                '&.MuiButtonBase-root': {
                  padding: 0,
                },
              }}
            />
            <div
              style={{
                fontFamily: langTheme.mainFont(),
                fontWeight: isRTL ? 400 : 500,
              }}
            >
              {t('term_policy')}
            </div>
          </div>

          {submitError && (
            <div
              className="submit-error"
              style={{
                fontFamily: langTheme.mainFont(),
                direction: i18n.dir(),
              }}
            >
              {submitError}
            </div>
          )}

          <button
            type="submit"
            className="signin-form-inputs-signin-btn"
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 700 : 500,
            }}
            disabled={hasError}
          >
            {t('create_my_account')}
          </button>
        </FormContainer>

        <div className="not-registred-yet-container">
          <div
            className="not-registred-yet-container-item"
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 400 : 500,
              color: 'rgba(43, 54, 116, 1)',
            }}
          >
            {t('already_a_member')}
          </div>
          <div
            className="not-registred-yet-container-item"
            // onClick={() => {
            //   navigate("/signin");
            //   dispatch(setSignupPopupClose());
            // }}
            onClick={() => handleGoToSignin()}
            style={{
              fontFamily: langTheme.mainFont(),
              fontWeight: isRTL ? 700 : 500,
              color: 'rgba(215, 161, 72, 1)',
              cursor: 'pointer',
              textTransform: 'capitalize',
            }}
          >
            {t('sign_in')}
          </div>
        </div>
      </div>
    </div>
  );
};
