import { AuthWrapper } from '../components/_common/AuthWrapper';
import { ChooseVerificationContent } from '../components/auth/ChooseVerificationContent';

export const ChooseVerification = () => {
  return (
    <AuthWrapper>
      <ChooseVerificationContent headerText={'choose_verification'} />
    </AuthWrapper>
  );
};
