import React from 'react';
import User from '../../assets/icons/user.svg';
import Sms from '../../assets/icons/sms.svg';
import Email from '../../assets/icons/email.svg';
import { useTranslation } from 'react-i18next';
import YellowArrow from '../../assets/icons/yellow_arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { useLanguageTheme } from '../../hooks/useLanguageTheme';
import {
  useSignupMutation,
  useSmsSignupMutation,
} from '../../store/api/authApiSlice';

export interface IResendSignupData {
  email: string;
}

export const ChooseVerificationContent = ({
  headerText,
}: {
  headerText: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['signin']);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'he';
  const langTheme = useLanguageTheme();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { email = 'example@mail.com', phoneNumber = '+123456789' } =
    useParams();

  const [signup] = useSignupMutation();
  const [signupSms] = useSmsSignupMutation();

  const handleSmsVerification = async () => {
    try {
      const registrationData = JSON.parse(
        localStorage.getItem('registrationData') || '{}',
      );
      await signupSms(registrationData).unwrap();
      navigate(`/confirm-sms/${phoneNumber}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailVerification = async () => {
    try {
      const registrationData = JSON.parse(
        localStorage.getItem('registrationData') || '{}',
      );
      await signup(registrationData).unwrap();
      navigate(`/confirm-email/${email}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="email-confirmation-section"
      style={{
        direction: i18n.dir(),
        height: downMd ? '100vh' : '',
      }}
    >
      <div className="email-confirmation-email-checked-container">
        <img src={User} alt="user icon" />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? 'Noto Sans Hebrew' : 'Frank Ruhl Libre',
        }}
      >
        {t(`${headerText}`)}
      </div>

      <div className="verification-option" onClick={handleSmsVerification}>
        <div>
          <img src={Sms} alt="sms icon" />
        </div>
        <div>
          <span>{t('verification_via_sms')}</span>
          <p>{phoneNumber}</p>
        </div>
      </div>

      <div className="verification-option" onClick={handleEmailVerification}>
        <div>
          <img src={Email} alt="email icon" />
        </div>
        <div>
          <span>{t('verification_via_email')}</span>
          <p>{email}</p>
        </div>
      </div>

      <div
        className="email-confirmation-yellow email-confirmation-back-to-signup"
        style={{
          fontFamily: langTheme.mainFont(),
          direction: 'ltr',
        }}
        onClick={() => navigate('/signup')}
      >
        <img src={YellowArrow} alt="arrow icon" />
        <div>{t('back_to_sign_up')}</div>
      </div>
    </div>
  );
};
