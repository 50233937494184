import React, { useEffect } from 'react';
import { AuthWrapper } from '../components/_common/AuthWrapper';
import EmailChecked from '../assets/icons/email_checked.svg';
import { useTranslation } from 'react-i18next';
import YellowArrow from '../assets/icons/yellow_arrow.svg';
import { useLanguageTheme } from '../hooks/useLanguageTheme';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { EmailConfirmationContent } from '../components/auth/EmailConfirmationContent';
import { SmsConfirmationContent } from '../components/auth/SmsConfirmationContent';

export const SmsConfirmation = () => {
  return (
    <AuthWrapper>
      <SmsConfirmationContent />
    </AuthWrapper>
  );
};
